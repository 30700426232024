import {createRouter, createWebHashHistory} from "vue-router";
import {checkToken} from '@/assets/js/api'

const enterUser = (to, from, next) => {
    if (localStorage.getItem('token')) {
        checkToken('user').then(res => {
            if (res.data.code === 200) {
                next()
            } else {
                localStorage.removeItem('token')
                next('/login')
            }
        })
    } else {
        next('/login')
    }
}

const enterLogin = (to, from, next) => {
    if (localStorage.getItem('token')) {
        next('/')
    } else {
        next()
    }
}

const enterAdmin = (to, from, next) => {
    if (localStorage.getItem('token')) {
        checkToken('admin').then(res => {
            if (res.data.code === 200) {
                next()
            } else {
                next({
                    path: '/login',
                    query: {
                        to: '/admin'
                    }
                })
            }
        })
    } else {
        next({
            path: '/login',
            query: {
                to: '/admin'
            }
        })
    }
}

export default createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: '/',
            name: 'index',
            component: () => import('../views/Index/Index.vue'),
            redirect: '/',
            children: [
                {
                    path: '/',
                    name: 'home',
                    component: () => import('@/views/Index/Home/Home.vue')
                },
                {
                    path: '/chat',
                    name: 'chat',
                    component: () => import('@/views/Index/Chat/Chat.vue')
                },
                {
                    path: '/book',
                    name: 'book',
                    component: () => import('@/views/Index/Book/Book.vue')
                },
                {
                    path: '/vip',
                    name: 'vip',
                    component: () => import('@/views/Index/Vip/Vip.vue')
                },
                {
                    path: '/link',
                    name: 'link',
                    component: () => import('@/views/Index/Link/Link.vue')
                },
                {
                    path: '/user',
                    name: 'user',
                    beforeEnter: enterUser,
                    component: () => import('@/views/Index/User/User.vue')
                },
                {
                    path: '/login',
                    name: 'login',
                    beforeEnter: enterLogin,
                    component: () => import('@/views/Index/User/Login.vue')
                },
                {
                    path: '/404',
                    name: '404',
                    component: () => import('@/views/NotFound.vue')
                },
                {
                    path: '/:pathMatch(.*)*',
                    name: 'redirect404',
                    redirect: '/404'
                }
            ]
        },
        {
            path: '/admin',
            name: 'admin',
            beforeEnter: enterAdmin,
            component: () => import('@/views/Admin/Index.vue'),
            redirect: '/admin/dashboard',
            children: [
                {
                    path: '/admin/dashboard',
                    name: 'adminDashboard',
                    component: () => import('@/views/Admin/Dashboard/Dashboard.vue')
                },
                {
                    path: '/admin/messageManage',
                    name: 'adminMessageManage',
                    component: () => import('@/views/Admin/Message/MessageManage.vue')
                },
                {
                    path: '/admin/settings',
                    name: 'adminSettings',
                    component: () => import('@/views/Admin/Settings/Settings.vue')
                },
                {
                    path: '/admin/userManage',
                    name: 'adminUserManage',
                    component: () => import('@/views/Admin/User/UserManage.vue')
                },
                {
                    path: '/admin/cardPass',
                    name: 'adminCardPass',
                    component: () => import('@/views/Admin/Card/CardPass.vue')
                },
                {
                    path: '/admin/linkManage',
                    name: 'adminLinkManage',
                    component: () => import('@/views/Admin/Link/Link.vue')
                }
            ]
        },
    ]
})