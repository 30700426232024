import {createStore} from "vuex";

export default createStore({
    state: {
        isLogin: false,
        user: {},
        theme: localStorage.getItem('theme') ? localStorage.getItem('theme') : 'light',
        activeTab: localStorage.getItem('activeTab') ? localStorage.getItem('activeTab') : 1,
        messageActiveKey: localStorage.getItem('messageActiveKey') ? localStorage.getItem('messageActiveKey') : 'home',
        settingActiveKey: localStorage.getItem('settingActiveKey') ? localStorage.getItem('settingActiveKey') : 'basic',
        userManageActiveKey: localStorage.getItem('userManageActiveKey') ? localStorage.getItem('userManageActiveKey') : 'user',
        uploadFileActiveKey: localStorage.getItem('uploadFileActiveKey') ? localStorage.getItem('uploadFileActiveKey') : 'upload',
        cardPassActiveKey: localStorage.getItem('cardPassActiveKey') ? localStorage.getItem('cardPassActiveKey') : 'add',
        linkManageActiveKey: localStorage.getItem('linkManageActiveKey') ? localStorage.getItem('linkManageActiveKey') : 'add',
    },
    mutations: {
        setLogin(state, data) {
            state.isLogin = data;
        },
        setUser(state, data) {
            state.user = data;
        },
        setTheme(state, data) {
            state.theme = data;
            localStorage.setItem('theme', data);
        },
        setActiveTab(state, data) {
            state.activeTab = data;
            localStorage.setItem('activeTab', data);
        },
        setMessageActiveKey(state, data) {
            state.messageActiveKey = data;
            localStorage.setItem('messageActiveKey', data);
        },
        setSettingActiveKey(state, data) {
            state.settingActiveKey = data;
            localStorage.setItem('settingActiveKey', data);
        },
        setUserManageActiveKey(state, data) {
            state.userManageActiveKey = data;
            localStorage.setItem('userManageActiveKey', data);
        },
        setUploadFileActiveKey(state, data) {
            state.uploadFileActiveKey = data;
            localStorage.setItem('uploadFileActiveKey', data);
        },
        setCardPassActiveKey(state, data) {
            state.cardPassActiveKey = data;
            localStorage.setItem('cardPassActiveKey', data);
        },
        setLinkManageActiveKey(state, data) {
            state.linkManageActiveKey = data;
            localStorage.setItem('linkManageActiveKey', data);
        }
    },
    actions: {}
})