import {createApp} from 'vue'
import App from './App.vue'
import naive from 'naive-ui'
import router from './router'
import store from "./store"
import axios from 'axios'
import 'highlight.js/styles/atom-one-dark.css'
import 'highlight.js/lib/common'
import 'vfonts/Lato.css'
import 'vfonts/FiraCode.css'
import '@/assets/fontawesome/css/fontawesome.css'
import '@/assets/fontawesome/css/brands.css'
import '@/assets/fontawesome/css/solid.css'
import '@/assets/css/style.css'

// axios.defaults.baseURL = 'http://192.168.1.7:8000'
// axios.defaults.baseURL = 'http://127.0.0.1:8000'

createApp(App)
    .use(naive)
    .use(router)
    .use(store)
    .mount('#app')
