import axios from 'axios'


/**
 * 获取信息
 * @param kwd
 * @returns {Promise<AxiosResponse<any>>}
 */
const getMessageByKwd = async (kwd) => {
    return await axios.get('/api/message/list/?kwd=' + kwd + '&type=index', {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}


/**
 * 搜索信息
 * @param kwd
 * @returns {Promise<AxiosResponse<any>>}
 */
const queryMessage = async (kwd) => {
    return await axios.get('/api/message/query/?kwd=' + kwd, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}


/**
 * 获取分类
 * @param kwd
 * @param type
 * @returns {Promise<AxiosResponse<any>>}
 */
const getMessageType = async (kwd = '', type = '') => {
    return await axios.get(`/api/message/list/type/?kwd=${kwd}&type=${type}`, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}


/**
 * 获取分类
 * @param kwd
 * @returns {Promise<AxiosResponse<any>>}
 */
const getMessageClass = async (kwd = '', type = '') => {
    return await axios.get(`/api/message/list/class/?kwd=${kwd}&type=${type}`, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}


/**
 * 获取信息
 * @param typeId
 * @returns {Promise<AxiosResponse<any>>}
 */
const getMessageByTypeId = async (typeId) => {
    return await axios.get('/api/message/list/type/msg/?typeId=' + typeId, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}


/**
 * 获取信息
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
const getMessageById = async (id) => {
    return await axios.get('/api/message/list/msg/?id=' + id, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}


/**
 * 获取信息
 * @param kwd
 * @param typeName
 * @param className
 * @param type
 * @returns {Promise<AxiosResponse<any>>}
 */
const getMessage = async (kwd = '', typeName = '', className = '', type = '') => {
    return await axios.get(`/api/message/list/?kwd=${kwd}&typeName=${typeName}&className=${className}&type=${type}`, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}


/**
 * 获取分组
 * @param type
 * @returns {Promise<AxiosResponse<any>>}
 */
const getGroup = async (type = 'option') => {
    return await axios.get(`/api/message/group/list/?type=${type}`, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}

/**
 * 获取编辑信息
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
const getEditMessage = async (id) => {
    return await axios.get('/api/message/edit/get/?id=' + id, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}


/**
 * 编辑信息
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
const editMessage = async (data) => {
    return await axios.post('/api/message/edit/', data, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}


/**
 * 获取编辑信息
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
const getEditMessageType = async (id) => {
    return await axios.get('/api/message/type/edit/get/?id=' + id, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}

/**
 * 编辑信息
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
const editMessageType = async (data) => {
    return await axios.post('/api/message/type/edit/', data, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}


/**
 * 获取编辑信息
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
const editMessageClass = async (data) => {
    return await axios.post('/api/message/class/edit/', data, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}


/**
 * 获取编辑信息
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
const getEditMessageClass = async (id) => {
    return await axios.get('/api/message/class/edit/get/?id=' + id, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}

/**
 * 添加信息
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
const addMessage = async (data) => {
    return await axios.post('/api/message/add/', data, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}


/**
 * 添加信息
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
const addGroup = async (data) => {
    return await axios.post('/api/user/group/add/', data, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}

const editGroup = async (data) => {
    return await axios.post('/api/user/group/edit/', data, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}

const getEditGroup = async (id) => {
    return await axios.get('/api/user/group/edit/get/?id=' + id, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}

const deleteGroup = async (data) => {
    return await axios.post('/api/user/group/delete/', data, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}

/**
 * 添加信息
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
const addClass = async (data) => {
    return await axios.post('/api/message/class/add/', data, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}

/**
 * 添加信息
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
const addType = async (data) => {
    return await axios.post('/api/message/type/add/', data, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}


/**
 * 删除信息
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
const deleteMessage = async (data) => {
    return await axios.post('/api/message/delete/', data, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}


/**
 * 删除信息
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
const deleteMessageType = async (data) => {
    return await axios.post('/api/message/type/delete/', data, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}


/**
 * 删除信息
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
const deleteMessageClass = async (data) => {
    return await axios.post('/api/message/class/delete/', data, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}

/**
 * 修改状态
 * @param id
 * @param status
 * @param type
 * @returns {Promise<AxiosResponse<any>>}
 */
const changeStatus = async (id, status, type) => {
    let formData = new FormData()
    formData.append('id', id)
    formData.append('status', status)
    formData.append('type', type)
    return await axios.post('/api/message/change/status/', formData, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}


/**
 * 获取用户信息
 * @returns {Promise<AxiosResponse<any>>}
 */
const getUserInfo = async () => {
    return await axios.get('/api/user/info/', {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}

/**
 * 检查token
 * @param type
 * @returns {Promise<AxiosResponse<any>>}
 */
const checkToken = async (type) => {
    return await axios.get('/api/user/token/check/?type=' + type, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}


const getFileList = async (kwd) => {
    return await axios.get(`/api/file/list/?kwd=${kwd}`, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}

/**
 * 用户登录
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
const userLogin = async (data) => {
    return await axios.post('/api/user/login/', data)
}


/**
 * 用户注册
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
const userRegister = async (data) => {
    return await axios.post('/api/user/register/', data)
}


/**
 * 获取用户权限
 * @returns {Promise<AxiosResponse<any>>}
 */
const getUserRight = async () => {
    return await axios.get('/api/user/right/', {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}


/**
 * 获取用户列表
 * @returns {Promise<AxiosResponse<any>>}
 */
const getUserList = async (kwd) => {
    return await axios.get(`/api/user/list/?kwd=${kwd}`, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}

const deleteUser = async (id) => {
    let data = new FormData()
    data.append('id', id)
    return await axios.post('/api/user/delete/', data, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}

const addUserToRole = async (group, role, user) => {
    let data = new FormData()
    data.append('group', group)
    data.append('role', role)
    data.append('user', user)
    return await axios.post('/api/user/role/add/to/', data, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}

const editUser = async (data) => {
    return await axios.post('/api/user/edit/', data, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}

const fileUpload = async (fromData) => {
    return await axios.post('/api/file/upload/', fromData, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}

const getRoleList = async (data) => {
    return await axios.post('/api/user/role/list/', data, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}

const addRole = async (data) => {
    return await axios.post('/api/user/role/add/', data, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}

const deleteRole = async (data) => {
    return await axios.post('/api/user/role/del/', data, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}
const deleteUserRole = async (data) => {
    return await axios.post('/api/user/role/delete/', data, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}

const changeRoleRight = async (id, type, value) => {
    let data = new FormData()
    data.append('id', id)
    data.append('type', type)
    data.append('value', value)
    return await axios.post('/api/user/role/right/change/', data, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}

const getConfig = async (data) => {
    return await axios.post('/api/config/get/', data, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}

const saveSetting = async (data) => {
    return await axios.post('/api/config/save/', data, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}

const sendEmailText = async (data) => {
    return await axios.post('/api/email/send/text/', data, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}

const getCardPassList = async (data) => {
    return await axios.post('/api/card/pass/list/', data, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}

const addCardPass = async (data) => {
    return await axios.post('/api/card/pass/add/', data, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}

const deleteCardPass = async (data) => {
    return await axios.post('/api/card/pass/delete/', data, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}

const getLink = async (data) => {
    return await axios.post('/api/link/list/', data, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}

const addLink = async (data) => {
    return await axios.post('/api/link/add/', data, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}

const changeLinkStatus = async (data) => {
    return await axios.post('/api/link/change/status/', data, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}

const deleteLink = async (data) => {
    return await axios.post('/api/link/delete/', data, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}

const changeUserPassword = async (data) => {
    return await axios.post('/api/user/change/password/', data, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}

const exchangeVip = async (data) => {
    return await axios.post('/api/user/exchange/vip/', data, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}


const getDashBoardList = async () => {
    return await axios.get('/api/dashboard/list/', {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}
export {
    getMessageByKwd,
    queryMessage,
    getMessageType,
    getMessageClass,
    getMessageByTypeId,
    getMessageById,
    getMessage,
    getEditMessage,
    changeStatus,
    editMessage,
    getUserInfo,
    checkToken,
    userLogin,
    userRegister,
    addMessage,
    deleteMessage,
    deleteMessageType,
    deleteMessageClass,
    getGroup,
    addClass,
    addType,
    getUserRight,
    editMessageType,
    getEditMessageType,
    editMessageClass,
    getEditMessageClass,
    getUserList,
    editUser,
    fileUpload,
    addGroup,
    deleteGroup,
    deleteUserRole,
    addRole,
    editGroup,
    getEditGroup,
    changeRoleRight,
    addUserToRole,
    deleteUser,
    deleteRole,
    getFileList,
    getConfig,
    getRoleList,
    saveSetting,
    sendEmailText,
    getCardPassList,
    addCardPass,
    deleteCardPass,
    getLink,
    addLink,
    deleteLink,
    changeLinkStatus,
    changeUserPassword,
    exchangeVip,
    getDashBoardList
}