<template>
  <n-config-provider :date-locale="dateZhCN" :locale="zhCN" :theme="data.theme">
    <n-modal-provider>
      <n-message-provider>
        <n-global-style/>
        <router-view/>
      </n-message-provider>
    </n-modal-provider>
  </n-config-provider>
</template>

<script>
import {defineComponent, reactive, watch} from 'vue'
// theme
import {darkTheme, dateZhCN, lightTheme, NConfigProvider, NDatePicker, NInput, NSpace, zhCN} from 'naive-ui'
import {useStore} from "vuex";
import {getConfig} from "@/assets/js/api";

export default defineComponent({
  components: {
    NConfigProvider,
    NInput,
    NDatePicker,
    NSpace
  },
  setup() {
    const store = useStore()
    const data = reactive({
      theme: store.state.theme === 'dark' ? darkTheme : lightTheme,
    })
    watch(() => store.state.theme, (newVal, oldVal) => {
      data.theme = newVal === 'dark' ? darkTheme : lightTheme
    })
    getConfig({
      require: [
        'webName',
        'webFavicon',
        'webDesc',
        'webKeywords'
      ]
    }).then(res => {
      if (res.data.code === 200) {
        document.title = res.data.data.webName
        document.querySelector('link[rel="icon"]').href = res.data.data.webFavicon
        document.querySelector('meta[name="description"]').content = res.data.data.webDesc
        document.querySelector('meta[name="keywords"]').content = res.data.data.webKeywords
      }
    }).catch(err => {
      console.log(err)
    })
    return {
      darkTheme,
      lightTheme,
      zhCN,
      dateZhCN,
      data
    }
  }
})
</script>

<style>
</style>
